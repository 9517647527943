import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { DataAccessService } from 'src/app/shared/services/data-access/data-access.service';
import { FilterRouteState, LocalStorage } from '../../entity/enums';
import { FilterAppliedChanges, RouteStateMeta, SelectionOption } from '../../entity/utility-interfaces';
import { DataRepositoryService } from '../../services/data-repository/data-repository.service';
import { Teams } from '../../models/business-location-details';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {

  //Full list of data variables 
  businessLocationsList: SelectionOption[] = [];
  blTeamsList: SelectionOption[] = [];
  blRoutesList: SelectionOption[] = [];

  //active BL's team and route collection variables
  storedBLTeamsList: any[] = [];
  storedBLRoutesList: any[] = [];
  storedSelectedBlTeams: SelectionOption[] = [];
  storedSelectedBlRoutes: SelectionOption[] = [];

  //selected options
  selectedBLId = '';
  selectedBLObject: SelectionOption | undefined;
  selectedBlTeams: SelectionOption[] = [];
  selectedBlRoutes: SelectionOption[] = [];

  display: boolean = false;
  isFilterDisabled: boolean = true;
  selectedDate: Date;
  minDate: Date;
  maxDate: Date;
  filterIcon: string = "pi pi-filter-slash";
  routeStates: RouteStateMeta[] = [
    { state: FilterRouteState.AllRoutes, isSelected: true, label: 'All Routes', apiState: 'All' },
    { state: FilterRouteState.Sorting, isSelected: false, label: 'Sorting', apiState: 'Sorting' },
    { state: FilterRouteState.Loading, isSelected: false, label: 'Loading', apiState: 'Loading' },
    { state: FilterRouteState.OnRoute, isSelected: false, label: 'On Route', apiState: 'OnRoute' }
  ]
  selectedRouteState: any = { state: FilterRouteState.AllRoutes, isSelected: true, label: 'All Routes', apiState: 'All' };
  appliedFilterChanges: FilterAppliedChanges =  {
    isBlChanged: false,
    isTeamChanged: false,
    isRouteChanged: false,
    isRouteStateChanged: false,
    isCalendarDateChanged: false,
  };
  isClearAllApplied: boolean = false;

  constructor(
    public dataAccessService: DataAccessService
    , public authService: AuthService
    , private commonService: CommonService
    , private dataRepositoryService: DataRepositoryService
  ) { }

  //Component Lifecycle hooks

  ngOnInit(): void {
    this.getBLData();
  }


  //Public methods

  getBLData(): void{
    this.dataAccessService.getBLData().subscribe(
      (res) => {
        this.businessLocationsList = res;
        const bl: any = localStorage.getItem(LocalStorage.BusinessLocation)
        if (!!bl) {
          this.selectedBLId = '' + JSON.parse(bl) + '';
        }
        this.isFilterApplied();

        this.getStoredValues();
        //set the filter icon view 
        this.setFilterIcon();
        this.dataAccessService.isBLSelected.next(true);
      }
    );
  }

  onBLSelection(selectedValue: any) {
    //check if a new business location is selected then clear teams and routes values
    if (selectedValue != this.selectedBLId) {
      this.blTeamsList = [];
      this.blRoutesList = [];
      this.selectedBlTeams = [];
      this.selectedBlRoutes = [];
      this.appliedFilterChanges.isBlChanged = true;

    }
    //enable the filter button
    this.isFilterDisabled = false;
    this.selectedBLId = selectedValue;
    this.selectedBLObject = this.businessLocationsList.find(f=>f.id===this.selectedBLId.toString()); 
    /**
     * To check if clearAllSelection is triggered, then cache value 
     * of dateoffset should NOT be read, instead selected date value should
     * be considered.
     */
    if(this.isClearAllApplied){
      let selectedDateOffset = (this.selectedDate.getDate() - (new Date()).getDate());
      this.getTeamAndRouteData(selectedValue, selectedDateOffset);
      this.isClearAllApplied = false;
    }
    else{
      this.getTeamAndRouteData(selectedValue);
    }
  }

  /**
   * Method clears the selection from all controls
   */
  onClearSelection() {
    this.blTeamsList = [];
    this.blRoutesList = [];
    this.selectedBLId = '';
    this.selectedBlTeams = [];
    this.selectedBlRoutes = [];
    this.selectedRouteState = this.routeStates[0];
    this.selectedBLObject = {} as SelectionOption;
    this.selectedDate = new Date();
    this.isFilterDisabled = true;
    this.isClearAllApplied = true;
  }

  /**
   * Method gets the Teams and Route data for selected Business location and optional 
   * dateoffset
   * @param blId selected business location id
   * @param dateOffset optional param - selected calendar date 
   */
  getTeamAndRouteData(blId: string, dateOffset?: number){
    this.dataAccessService.getBLTeamsRouteList(blId, dateOffset).subscribe({
      next: (res) => {
        //First Index of the response is costCenter data and Second is Routes data
        
        if(res){
          this.storedBLTeamsList = [...res[0]];
          this.blTeamsList = [];
          this.blTeamsList = [...res[0]].map((team: any) => ({
            id: team.cost_center_id, value: team.name, isDisabled: false
          }));
  
          //routes
          this.storedBLRoutesList = [...res[1]];
          this.blRoutesList = [];
          
          this.blRoutesList = [...res[1]].map((route: any) => ({
            id: route.route_label, value: route.route_label, isDisabled: false
          }));

          if (!this.appliedFilterChanges.isBlChanged && !this.appliedFilterChanges.isCalendarDateChanged) {
            this.getStoredSelectedTeamValues();
            this.getStoredSelectedRouteValue();
            this.setTeamRouteAssociationValues()
          }
        }

      }
      ,error:(err:any)=>{}
      ,complete: ()=>{}
    })
  }

  onTeamsSelection(selectedValue: any) {
    if(selectedValue.length >= this.selectedBlTeams.length){
      //get the cost_center_id from the selected Value and check the route association
      this.checkTeamRouteAssociation(selectedValue[selectedValue.length-1]?.id);
    }
    else if(selectedValue.length < this.selectedBlTeams.length){
      this.setTeamRouteDisassociation(this.selectedBlTeams.filter(fl=>!selectedValue.find((f:any)=>f?.id==fl?.id))[0]?.id);
    }

    this.selectedBlTeams = selectedValue;
    this.appliedFilterChanges.isTeamChanged = true;
    this.isFilterDisabled = false;
  }

  onRouteSelection() {
    this.appliedFilterChanges.isRouteChanged = true;
    this.isFilterDisabled = false
  }

  onRouteStateChange(){
    if (!!this.selectedBLObject?.id && this.selectedBLObject?.id !=='') {
      this.isFilterDisabled = false
    }
  }
  
  onDateSelection(){
    if (!!this.selectedBLObject?.id && this.selectedBLObject?.id !=='') {
      this.appliedFilterChanges.isCalendarDateChanged = true;
      this.isFilterDisabled = false;

      //clear the selected route and selected teams 
      this.blTeamsList = [];
      this.blRoutesList = [];
      this.selectedBlTeams = [];
      this.selectedBlRoutes = [];
      this.storedBLRoutesList = [];
      this.storedBLTeamsList = [];

      //call the new teams and route list for new date
      let selectedDateOffset = this.calculateDateOffset();
      this.getTeamAndRouteData(this.selectedBLId, selectedDateOffset);
    }
  }

  /**
   * Method calculates the dateoffet value by comparing months and date of the 
   * selected date from calendar and current system date.
   * @returns calculated dateoffset value 
   */
  calculateDateOffset(){
    let dateOffset = 0;
    let currentDate = new Date();
    let selectedDate = this.selectedDate;
    // Calculate the difference in total days between the two dates
    dateOffset = Math.ceil((selectedDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
    return dateOffset;
  }

  checkTeamRouteAssociation(cost_center_id:string){
    let matchedRoutes = this.storedBLRoutesList.filter(f=>f.cost_center_id===cost_center_id);
    let associatedRoutes = matchedRoutes?.map((route:any)=>({id: route.route_label, value: route.route_label, isDisabled: true}));
    //check the route intersections (team's route and user selected routes are intersecting)
    let intersectRoutes = associatedRoutes.filter(f=> this.selectedBlRoutes.map(m=>m.id).includes(f.id));
    intersectRoutes?.forEach(intersectRoute=>{
      let index = this.selectedBlRoutes.findIndex(selRoute=>selRoute.id===intersectRoute.id);
      if(index!==-1){
        this.selectedBlRoutes.splice(index,1);
      }
    })

    this.checkSelectedRouteAvailability();
    
    this.selectedBlRoutes = [...this.selectedBlRoutes,...associatedRoutes];
    
    //set the Map collection with this association 
    if(!!matchedRoutes && matchedRoutes.length){
      this.dataRepositoryService.selectedTeamRouteAssociation.set(cost_center_id,matchedRoutes);
    }
    //update the BLRoutelist 
    this.blRoutesList.forEach(route=>{
      route.isDisabled = associatedRoutes.findIndex(f=>f.id==route.id) != -1 ? true :  route.isDisabled
    })
  }

  /**
   * Method checks if the route selected and saved previously, has now been removed from API response of routes
   */
  checkSelectedRouteAvailability(){
    //check if the route selected and saved previously, has now been removed from API response of routes
    let removedSelectedRoutes = this.selectedBlRoutes.filter(f=> !this.blRoutesList.map(m=>m.id).includes(f.id));
    removedSelectedRoutes?.forEach(removedSelectedRoute=>{
      let index = this.selectedBlRoutes.findIndex(selRoute=>selRoute.id===removedSelectedRoute.id);
      if(index!==-1){
        this.selectedBlRoutes.splice(index,1);
      }
    })
  }

  setTeamRouteDisassociation(cost_center_id:string){
    let matchedRoutes = this.storedBLRoutesList.filter(f=>f.cost_center_id===cost_center_id);
    this.selectedBlRoutes = this.selectedBlRoutes.filter(selRoute=>!matchedRoutes.find(mathcedRoute=>mathcedRoute.route_label=== selRoute.id)) 

    //remove from Map object
    this.dataRepositoryService.selectedTeamRouteAssociation.delete(cost_center_id);

    //update the BLRoutelist 
    this.blRoutesList.forEach(route=>{
      route.isDisabled = matchedRoutes.findIndex(f=>f.route_label==route.id) != -1 ? false :  route.isDisabled
    })
  }

  applyFilter() {
    //store date offset in data repository
    this.dataRepositoryService.dateOffset = this.calculateDateOffset();

    //store route state in data repository
    this.dataRepositoryService.selectedFilteredRouteState = this.selectedRouteState.apiState;

    //store BL id in data repository
    let blAddress;
    if (this.selectedBLId != '') {
      this.dataRepositoryService.selectedBusinessLocation = this.selectedBLId;
      blAddress = this.dataRepositoryService.businessLocationAddress.get(this.selectedBLId);
      if(blAddress?.coordinate){
        this.dataRepositoryService.blCoordinates = blAddress?.coordinate
      }
    }
    
    let coords = blAddress?.coordinate;
    //store allvalues in local storage
    this.setLocalStorage(LocalStorage.BusinessLocation, JSON.stringify(this.selectedBLId));
    this.setLocalStorage(LocalStorage.RouteState, JSON.stringify(this.dataRepositoryService.selectedFilteredRouteState));
    this.setLocalStorage(LocalStorage.BLCoordinates, JSON.stringify(coords));

    //store teams values in local storage
    this.formatBLTeams();
    //store Route values in local storage
    this.formatBLRoutes();

    //set the filter icon view 
    this.setFilterIcon();
    this.dataAccessService.setFilterApplied(true, this.appliedFilterChanges)
    this.resetControlSelection();
  }

  setLocalStorage(localStorageLabel:string, input:string){
    //store allvalues in local storage
    localStorage.setItem(localStorageLabel, input);
  }

  closePopup() {
    this.resetControlSelection();
  }

  resetControlSelection(){
    this.display = false;
    this.isFilterDisabled = true;
    this.appliedFilterChanges = {
      isBlChanged:false,
      isCalendarDateChanged: false,
      isRouteChanged: false,
      isRouteStateChanged: false,
      isTeamChanged: false,
    };
    this.selectedBLId = '';
    this.selectedBlTeams = []
    this.selectedBlRoutes = [];
  }

  getStoredValues() {
    this.getDate();
    this.minDate = new Date();
    this.maxDate = new Date();

    this.minDate = new Date(this.minDate.setDate(new Date().getDate() - 5));
    this.maxDate = new Date(this.maxDate.setDate(new Date().getDate() + 1));
    this.getRouteState();
    //store date offset in data repository
    this.dataRepositoryService.dateOffset = (this.selectedDate.getDate() - (new Date()).getDate());

    //store route state in data repository
    this.dataRepositoryService.selectedFilteredRouteState = this.selectedRouteState.apiState;

  }

  getRouteState(){
    let storedRouteState: any = localStorage.getItem(LocalStorage.RouteState);
    if (storedRouteState != null && JSON.parse(storedRouteState) != '') {
      this.selectedRouteState = this.routeStates[this.routeStates.findIndex(rs => rs.apiState.toLowerCase() == JSON.parse(storedRouteState).toLowerCase())];
    }
    else {
      this.selectedRouteState = this.routeStates[0];
    }
  }

  getDate(){
    let storedDateOffset: any = this.dataRepositoryService.dateOffset;
    if (storedDateOffset && JSON.parse(storedDateOffset) != '' && (JSON.parse(storedDateOffset) <= 1 || JSON.parse(storedDateOffset) >= -5)) {
      let date = new Date(new Date().setDate(new Date().getDate() + JSON.parse(storedDateOffset)));

      this.selectedDate = this.commonService.dateFormatter(date);
    }
    else {
      this.selectedDate = this.commonService.dateFormatter(new Date());
    }
  }
  updateRepoforBLTeams() {
    let blTeams: Map<string, Teams> = new Map<string, Teams>();
    this.storedBLTeamsList?.reduce((map: any, team) => {
      map.set(team.cost_center_id, team);
      return map;
    }, blTeams);
    if (blTeams.size) {
      this.dataRepositoryService.teamList.clear();
      this.dataRepositoryService.teamList = blTeams;

    }
  }

  updateRepoForBLRoutes(){
    if (this.storedBLRoutesList.length) {
      this.dataRepositoryService.blAllRouteList = [];
      this.dataRepositoryService.blAllRouteList = this.storedBLRoutesList.map(route => route.route_label);
    }
  }

  formatBLTeams() {
    //update BLTeams Map data set

    this.updateRepoforBLTeams();
    this.dataRepositoryService.selectedTeamList.clear();
    this.selectedBlTeams?.forEach((team: any) => {
      this.dataRepositoryService.selectedTeamList.set(team.id, team);
    })
    localStorage.setItem(LocalStorage.BLSelectedTeams, JSON.stringify([...this.dataRepositoryService.selectedTeamList]));
  }

  formatBLRoutes() {
    //update BLRoute Map data set
    this.updateRepoForBLRoutes();
    this.dataRepositoryService.selectedFilteredRouteList = [];
    let selectedTeamsRoutes = [...this.dataRepositoryService.selectedTeamRouteAssociation.values()];
    this.selectedBlRoutes?.forEach((route: SelectionOption) => {
      if (selectedTeamsRoutes?.length) {
        if (!selectedTeamsRoutes.flat().map((m: any) => m.route_label).includes(route.id)) {
          this.dataRepositoryService.selectedFilteredRouteList.push(route.value);
        }
      }
      if(!selectedTeamsRoutes?.length){
        this.dataRepositoryService.selectedFilteredRouteList.push(route.value);
      }
    })
    localStorage.setItem(LocalStorage.BLSelectedRoutes, JSON.stringify(this.dataRepositoryService.selectedFilteredRouteList.toString()));
  }

  getStoredSelectedTeamValues(){
    let teams: any = localStorage.getItem(LocalStorage.BLSelectedTeams)
    if (!!teams && JSON.parse(teams).length) {
      let teamsMapObj: any = [...(new Map(JSON.parse(teams))).values()];
      this.selectedBlTeams = teamsMapObj;

    }
    else{
      this.selectedBlTeams = [];
    }
  }

  getStoredSelectedRouteValue(){
    let routes: any = localStorage.getItem(LocalStorage.BLSelectedRoutes);
    if (!!routes && JSON.parse(routes) != '') {
      let routesMapObj: SelectionOption[] = JSON.parse(routes).split(',').map((route: any) => ({
        id: route, value: route, isDisabled: false
      }));
      this.selectedBlRoutes = [];
      this.selectedBlRoutes = routesMapObj;
    }
    else {
      this.selectedBlRoutes = [];
    }
  }

  /**
   * check the associat?ed routes and make those route disable in routes dropdown
   */
  setTeamRouteAssociationValues(){
    this.selectedBlTeams?.forEach(selTeam=>this.checkTeamRouteAssociation(selTeam.id)); 
  }

  isFilterApplied() {
    if (this.selectedBLId !== '') {
      this.blTeamsList = [];
      this.blTeamsList = [{
        id: '', value: ''
      }]
      this.blRoutesList = [];

      if (!this.selectedBlTeams.length) {
        let teams: any = localStorage.getItem(LocalStorage.BLSelectedTeams)
        if (!!teams && JSON.parse(teams).length) {
          let teamsMapObj: any = [...(new Map(JSON.parse(teams))).values()];
          this.selectedBlTeams = teamsMapObj;
        }
      }
      if (!this.selectedBlRoutes.length) {
        let routes: any = localStorage.getItem(LocalStorage.BLSelectedRoutes);
        if (!!routes && JSON.parse(routes) != '') {
          let routesMapObj: SelectionOption[] = JSON.parse(routes).split(',').map((route: any) => ({
            id: route, value: route, isDisabled: false
          }));
          this.selectedBlRoutes = routesMapObj;
        }
      }
    }
    else {
      this.filterIcon = "pi pi-filter-slash";
      this.showDialog()
    }
  }

  showDialog() {
    this.display = true;
    this.setSelectionValues();
  }

  setSelectionValues() {
    this.selectedBLObject = {} as SelectionOption;
    this.selectedBlRoutes = [];
    this.selectedBlTeams = [];
    if (this.dataRepositoryService?.businessLocationListMap.size) {
      this.businessLocationsList = [];
      this.businessLocationsList = [...this.dataRepositoryService.businessLocationListMap.values()].reduce((acc: any, bl: any) => {
        acc.push({ id: bl.business_location_id, value: bl.name });
        return acc;
      }, [])
    }
    
    const bl: any = localStorage.getItem(LocalStorage.BusinessLocation);
    if (!!bl) {
      let blid = JSON.parse(bl)
      this.selectedBLObject = this.businessLocationsList.find(f=>f.id===blid.toString());
      this.selectedBLId = blid.toString();
    }else{
      this.selectedBLObject = {} as SelectionOption;
    }
    this.getRouteState();

    this.getDate();

    //NOTE below onwards teams and route API should be called
    if(!!bl){
      this.getTeamAndRouteData(JSON.parse(bl))
    }

    //set the filter icon view 
    this.setFilterIcon();

  }

  /**
   * Method set the filter icon view as per the selection made by user or selection
   * retrieved from local storage
   */
  setFilterIcon(){
    if(this.selectedBLId === ""){
      this.filterIcon = "pi pi-filter-slash";
    }
    else {
      let teams: any = localStorage.getItem(LocalStorage.BLSelectedTeams);
      let routes: any = localStorage.getItem(LocalStorage.BLSelectedRoutes);
      if (!!teams && JSON.parse(teams).length
        || !!routes && JSON.parse(routes) != ''
        || this.dataRepositoryService.selectedFilteredRouteState.toLowerCase() !== 'all'
        || this.dataRepositoryService.dateOffset !== 0
      ) {
        this.filterIcon = "pi pi-filter-fill";
      }
      else{
        this.filterIcon = "pi pi-filter";
      }
    } 
  }


}
